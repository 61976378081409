import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/service',
    name: 'service',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/ServiceView.vue')
  },
  {
    path: '/policy',
    name: 'policy',
    component: () => import('../views/PolicyView.vue')
  },
  {
    path: '/gear-list',
    name: 'gear-list',
    component: () => import('../views/GearListView.vue')
  },
  {
    path: '/collection',
    name: 'collection',
    component: () => import('../views/CollectionView.vue')
  },
  {
    path: '/collection/:id',
    name: 'collection-detail',
    component: () => import('../views/DetailCollectionView.vue')
  },
  {
    path: '/film-roll',
    name: 'film-roll',
    component: () => import('../views/FilmRollView.vue')
  },
  {
    path: '/social-network',
    name: 'social-network',
    component: () => import('../views/SocialNetworkView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
