import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import i18n from '@/i18n/i18n';
import FlagIcon from 'vue-flag-icon';
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import VueGtag from 'vue-gtag';


createApp(App)
    .use(VueI18n)
    .use(router)
    .use(i18n)
    .use(VueGtag, {
        config: { id: 'G-4XW2XM97WV' }
      }, router)
    .use(FlagIcon)
    .mount('#app')
