<template>
  <header class="">
    <div class="navbar navbar-default">
      <button type="button" class="navbar-toggle collapsed" :onclick="toggleMobileMenu">
        <span class="sr-only">{{ $t('navigateOpenMenu') }}</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>

      <div class="change-language">
        <!-- <button v-for="entry in languages" :key="entry.title" @click="changeLocale(entry.language)">
            <flag :iso="entry.flag" v-bind:squared=false />
            {{entry.title}}
        </button> -->
        <a v-for="entry in languages" :key="entry.title" @click.prevent="changeLocale(entry.language)" href="#" class="language">
          <flag :iso="entry.flag" v-bind:squared=false />
          {{entry.title}}
        </a>
      </div>
    </div>
    

    <nav class="sidebar" :class="{ 'open': showMobileMenu }">
      <div class="navbar-collapse" id="navbar-collapse">
        <div class="site-header hidden-xs">
          <a class="site-brand" href="./index.html" title="">
            <img class="img-responsive site-logo" alt="" src="./assets/images/mashup-logo.svg">
            {{ $t('navigateTitle') }}
          </a>
          <p> {{ $t('navigateSubTitle') }} </p>
        </div>
        <ul class="nav">
          <li><RouterLink to="/">{{ $t('navigateToHome') }}</RouterLink></li>
          <li><RouterLink to="/service">{{ $t('navigateToServices') }}</RouterLink></li>
          <li><RouterLink to="/collection">{{ $t('navigateToCollection') }}</RouterLink></li>
          <li><RouterLink to="/film-roll">{{ $t('navigateToFilmRolls') }}</RouterLink></li>
          <li><RouterLink to="/gear-list">{{ $t('navigateToGearList') }}</RouterLink></li>
          <li><RouterLink to="/policy">{{ $t('navigateToPolicy') }}</RouterLink></li>
          <li><RouterLink to="/social-network">{{ $t('navigateToSocialNetwork') }}</RouterLink></li>
          <li><RouterLink to="/about">{{ $t('navigateToAbout') }}</RouterLink></li>
        </ul>

        <nav class="nav-footer">
          <p class="nav-footer-social-buttons">
            <a class="fa-icon" href="https://www.instagram.com/" title="">
              <i class="fa fa-instagram"></i>
            </a>
            <a class="fa-icon" href="https://dribbble.com/" title="">
              <i class="fa fa-dribbble"></i>
            </a>
            <a class="fa-icon" href="https://twitter.com/" title="">
              <i class="fa fa-twitter"></i>
            </a>
          </p>
          <p>© Kien Nguyen | 2024</p>
        </nav>
      </div>
    </nav>
  </header>
  <router-view />
</template>


<!-- import the css from css folder -->
<style lang="scss">
@import './assets/css/main.82cfd66e.css';
</style>


<script>
import './assets/js/main.85741bff.js';
import i18n from './i18n/i18n';

export default {
  name: 'App',
  data() {
    return {
      showMobileMenu: false,
      languages: [
        { flag: 'us', language: 'en', title: 'English' },
        { flag: 'vn', language: 'vi', title: 'Tiếng Việt' },
        { flag: 'jp', language: 'jp', title: "日本語" }
      ]
    };
  },
  props: {

  },
  mounted() {
  
  },
  methods: {
    toggleMobileMenu() {
      this.showMobileMenu = !this.showMobileMenu;
    },

  changeLocale(locale) {
    i18n.global.locale = locale;
  }
  },
}
</script>

<style scoped>
.change-language {
  float: right;
  /* Alternatively, you can use flexbox */
  /* display: flex; */
  /* justify-content: flex-end; */
  margin-bottom: 15px;
  margin-left: 0px;
  margin-right: 15px;
  margin-top: 15px;
}
.language {
  margin-left: 10px;
}
</style>