<template>
  <div class="row">
    <div class="col-xs-12 col-md-6">
      <img class="img-responsive" alt="" src="../assets/images/avatar.jpg">
    </div>
    <div class="col-xs-12 col-md-6">
      <h1>{{ $t('homeAboutMeTitleLine') }}</h1>

      <p>
        {{ $t('homeAboutMeDescriptionLine') }}
      </p>

      <h3>{{ $t('homeAboutMeSecondLine') }}</h3>

      <p>
        {{ $t('homeAboutMeSecondDescriptionLine') }}
      </p>

      <h3>{{ $t('homeAboutMeThirdLine') }}</h3>
      <p>
        {{ $t('homeAboutMeThirdDescriptionLine') }}
      </p>

    </div>
  </div>
</template>

<script>
import i18n from '@/i18n/i18n';

export default {
  name: 'Home',
  props: {
    
  },
  mounted() {
    this.$watch(() => i18n.global.locale, (newLocale, oldLocale) => {
        document.title = this.$t('titleHome');
    });
    
    document.title = this.$t('titleHome');
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>

