<template>
  <main class="" id="main-collapse">
  <div class="hero-full-wrapper">
    <Home msg="Welcome to my homepage"/>
  </div>
</main>
</template>

<script>
// @ is an alias to /src
import Home from '@/components/Home.vue'

export default {
  name: 'HomeView',
  props: {
    
  },
  components: {
    Home
  }
}
</script>
