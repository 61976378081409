import { createI18n } from 'vue-i18n';
import Vue from 'vue';
import VueI18n from 'vue-i18n';

const messages = {
    'en': {
        welcomeMsg:  'Welcome to Your Vue.js App',

        titleHome: 'Home',
        titleService: 'Service',
        titlePolicy: 'Policy',
        titleGearList: 'Gear List',
        titleCollection: 'Collection',
        titleFilmRoll: 'Film Roll',
        titleSocialNetwork: 'Social Network',
        titleAbout: 'About',

        navigateOpenMenu: "Open menu",
        navigateTitle: "Kien Photos",
        navigateSubTitle: "This website to showcase for Kien's Art.",
        navigateToHome: "Home",
        navigateToServices: "Services",
        navigateToCollection: "Collection",
        navigateToFilmRolls: "Film Rolls showcase",
        navigateToGearList: "Gear List",
        navigateToPolicy: "Policy",
        navigateToSocialNetwork: "Social Network",
        navigateToAbout: "About this website",

        homeAboutMeTitleLine: "About me",
        homeAboutMeDescriptionLine: "Owned camera since I was 8, I started with a humble 400D and 18-55 kit lens. Now I am based on Hanoi city and specifically cosplay, and event photography. I am also a big fan of film photography, and I have a small collection of film cameras include large format, medium format, and 35mm film cameras.",
        homeAboutMeSecondLine: "Start to reentry photography in circa 2023",
        homeAboutMeSecondDescriptionLine: " I bought a used Canon 5D Mark II and 50 STM to start shooting in June 2023. I currently have some Canon flash to research about lighting concept. Some of my works are published on my Facebook account.",
        homeAboutMeThirdLine: "My notable works",
        homeAboutMeThirdDescriptionLine: "Currently I have many of beautiful photo shows on many notable social profile, you can check out my works on my social network profile.",

        serviceTitle: "Services",
        serviceSubTitle: "Below is my service, please contact for more detail.",
        serviceFestivalShootingTitle: "Festival Shooting",
        serviceFestivalShootingDesc: "Shooting in available cosplay festival with no transport required.",
        serviceStudioShootingTitle: "Studio Shooting",
        serviceStudioShootingDesc: "Shooting in studio with professional lighting and background or BYOD studio",
        serviceDeviceRentingTitle: "Device Renting",
        serviceDeviceRentingDesc: "Renting my personal equipment for your shooting.",
        serviceContactForDetail: "Contact for more detail",

        collectionDetailDownload: "Download",
        
        gearListTitle: "Gear List",
        gearListSubTitle: "Below is my gear list, please contact for more detail.",
        gearListCameraTitle: "Cameras",
        gearListFlashTitle: "Flashes",
        gearListLensTitle: "Lenses",
        gearListOtherTitle: "Others",

        policyTitle: "Policy",
        policySubTitle: "Below is my policy, please contact for more detail.",

        aboutThisWebsiteTitle: "About this website",
        aboutThisWebsiteDescriptionLine1: "Welcome to our website! We are a team of photographers passionate about capturing beautiful moments.",
        aboutThisWebsiteDescriptionLine2: "Our mission is to provide high-quality photography services that exceed our clients' expectations.",
        aboutThisWebsiteDescriptionLine3: "Feel free to browse through our portfolio and contact us for any inquiries or bookings.",
        aboutThisWebsiteCopyrightTitle: "Copyright © 2024",
        aboutThisWebsiteBuildBaseText: "Build on base source code of savory:",
    },
    'vi': {
        welcomeMsg:  'Chào mừng đến với ứng dụng Vue.js của bạn',

        titleHome: 'Trang chủ',
        titleService: 'Dịch vụ',
        titlePolicy: 'Chính sách',
        titleGearList: 'Danh sách thiết bị',
        titleCollection: 'Bộ sưu tập',
        titleFilmRoll: 'Bộ sưu tập Film',
        titleSocialNetwork: 'Mạng xã hội',
        titleAbout: 'Giới thiệu',

        navigateOpenMenu: "Mở menu",
        navigateTitle: "Bộ sưu tập ảnh của Kiên",
        navigateSubTitle: "Trang web này để giới thiệu cho nghệ thuật của Kiên.",
        navigateToHome: "Trang chủ",
        navigateToServices: "Dịch vụ",
        navigateToCollection: "Bộ sưu tập",
        navigateToFilmRolls: "Bộ sưu tập Film",
        navigateToGearList: "Danh sách thiết bị",
        navigateToPolicy: "Chính sách",
        navigateToSocialNetwork: "Mạng xã hội",
        navigateToAbout: "Giới thiệu về trang web này",

        homeAboutMeTitleLine: "Về tôi",
        homeAboutMeDescriptionLine: "Sở hữu máy ảnh phản xạ ống kính đơn từ khi tôi 8 tuổi, tôi bắt đầu với một chiếc Canon EOS 400D và ống kính EF-S 18-55mm f3.5-5.6. Hiện tôi đang sinh sống tại thành phố Hà Nội và chuyên chụp ảnh cosplay và sự kiện. Tôi cũng thích chụp ảnh bằng máy phim, do đó tôi sở hữu một bộ sưu tập nhỏ gồm máy ảnh film bao gồm large format, medium format và 35mm.",
        homeAboutMeSecondLine: "Bắt đầu trở lại với nhiếp ảnh vào khoảng năm 2023",
        homeAboutMeSecondDescriptionLine: "Tôi mua một chiếc Canon 5D Mark II và ống kính 50 STM cũ để bắt đầu chụp ảnh vào tháng 6 năm 2023. Hiện tôi có một cơ số đèn flash Canon để bắt đầu nghiên cứu về cách đánh sáng. Một số tác phẩm của tôi được đăng trên tài khoản Facebook của tôi.",
        homeAboutMeThirdLine: "Các tác phẩm đáng chú ý",
        homeAboutMeThirdDescriptionLine: "Hiện tôi có nhiều tác phẩm đẹp được đăng trên nhiều trang mạng xã hội nổi tiếng, bạn có thể xem các tác phẩm của tôi trên trang mạng xã hội của tôi.",

        serviceTitle: "Dịch vụ",
        serviceSubTitle: "Dưới đây là dịch vụ của tôi, vui lòng liên hệ để biết thêm chi tiết.",
        serviceFestivalShootingTitle: "Chụp ảnh tại lễ hội cosplay",
        serviceFestivalShootingDesc: "Chụp ảnh tại lễ hội cosplay.",
        serviceStudioShootingTitle: "Chụp ảnh tại studio",
        serviceStudioShootingDesc: "Chụp ảnh tại studio với ánh sáng và phông chuyên nghiệp hoặc tự trang bị studio",
        serviceDeviceRentingTitle: "Cho thuê thiết bị",
        serviceDeviceRentingDesc: "Cho thuê thiết bị cá nhân của tôi để chụp ảnh.",
        serviceContactForDetail: "Liên hệ để biết thêm chi tiết",

        collectionDetailDownload: "Tải về",

        gearListTitle: "Danh sách thiết bị",
        gearListSubTitle: "Dưới đây là danh sách thiết bị của tôi, vui lòng liên hệ để biết thêm chi tiết.",
        gearListCameraTitle: "Máy ảnh",
        gearListFlashTitle: "Đèn flash",
        gearListLensTitle: "Ống kính",
        gearListOtherTitle: "Khác",

        policyTitle: "Chính sách",
        policySubTitle: "Dưới đây là chính sách của tôi, vui lòng liên hệ để biết thêm chi tiết.",

        aboutThisWebsiteTitle: "Giới thiệu về trang web này",
        aboutThisWebsiteDescriptionLine1: "Chào mừng đến với trang web của chúng tôi! Chúng tôi là một nhóm nhiếp ảnh gia đam mê chụp lại những khoảnh khắc đẹp.",
        aboutThisWebsiteDescriptionLine2: "Nhiệm vụ của chúng tôi là cung cấp dịch vụ nhiếp ảnh chất lượng cao vượt quá mong đợi của khách hàng.",
        aboutThisWebsiteDescriptionLine3: "Hãy thoải mái duyệt qua bộ sưu tập của chúng tôi và liên hệ với chúng tôi để biết thông tin hoặc đặt lịch.",
        aboutThisWebsiteCopyrightTitle: "Bản quyền © 2024",
        aboutThisWebsiteBuildBaseText: "Xây dựng trên mã nguồn cơ bản của savory:",
    },
    'jp': {
        welcomeMsg:  'あなたのVue.jsアプリへようこそ',

        titleHome: 'ホーム',
        titleService: 'サービス',
        titlePolicy: 'ポリシー',
        titleGearList: '機材リスト',
        titleCollection: 'コレクション',
        titleFilmRoll: 'フィルムロール',
        titleSocialNetwork: 'ソーシャルネットワーク',
        titleAbout: 'このウェブサイトについて',

        navigateOpenMenu: "メニューを開く",
        navigateTitle: "キエンのアート",
        navigateSubTitle: "このウェブサイトはキエンのアートを紹介するためのものです。",
        navigateToHome: "ホーム",
        navigateToServices: "サービス",
        navigateToCollection: "コレクション",
        navigateToFilmRolls: "フィルムロール",
        navigateToGearList: "機材リスト",
        navigateToPolicy: "ポリシー",
        navigateToSocialNetwork: "ソーシャルネットワーク",
        navigateToAbout: "このウェブサイトについて",

        homeAboutMeTitleLine: "私について",
        homeAboutMeDescriptionLine: "8歳のときからカメラを所有しており、謙虚な400Dと18-55キットレンズで始めました。現在、私はハノイ市に拠点を置いており、特にコスプレとイベントの写真を撮影しています。また、フィルム写真の大ファンであり、ラージフォーマット、ミディアムフォーマット、35mmフィルムカメラを含むフィルムカメラの小さなコレクションを持っています。",
        homeAboutMeSecondLine: "2023年頃に写真に再参入",
        homeAboutMeSecondDescriptionLine: "2023年6月に撮影を開始するために中古のCanon 5D Mark IIと50 STMを購入しました。現在、私は照明の概念について調査するためにいくつかのCanonフラッシュを持っています。私の作品の一部は私のFacebookアカウントで公開されています。",
        homeAboutMeThirdLine: "私の注目すべき作品",
        homeAboutMeThirdDescriptionLine: "現在、私は多くの素晴らしい写真を多くの有名なソーシャルプロファイルで公開しており、私の作品は私のソーシャルネットワークプロファイルで確認できます。",

        serviceTitle: "サービス",
        serviceSubTitle: "以下は私のサービスです。詳細についてはお問い合わせください。",
        serviceFestivalShootingTitle: "フェスティバル撮影",
        serviceFestivalShootingDesc: "トランスポート不要の利用可能なコスプレフェスティバルでの撮影。",
        serviceStudioShootingTitle: "スタジオ撮影",
        serviceStudioShootingDesc: "プロの照明と背景またはBYODスタジオを使用したスタジオでの撮影",
        serviceDeviceRentingTitle: "デバイスレンタル",
        serviceDeviceRentingDesc: "撮影用に私の個人的な機器をレンタルする。",
        serviceContactForDetail: "詳細についてはお問い合わせください",

        collectionDetailDownload: "ダウンロード",
        
        gearListTitle: "機材リスト",
        gearListSubTitle: "以下は私の機材リストです。詳細についてはお問い合わせください。",
        gearListCameraTitle: "カメラ",
        gearListFlashTitle: "フラッシュ",
        gearListLensTitle: "レンズ",
        gearListOtherTitle: "その他",

        policyTitle: "ポリシー",
        policySubTitle: "以下は私のポリシーです。詳細についてはお問い合わせください。",
        
        aboutThisWebsiteTitle: "このウェブサイトについて",
        aboutThisWebsiteDescriptionLine1: "当ウェブサイトへようこそ！私たちは美しい瞬間を捉えることに情熱を持つ写真家チームです。",
        aboutThisWebsiteDescriptionLine2: "私たちの使命は、クライアントの期待を超える高品質な写真サービスを提供することです。",
        aboutThisWebsiteDescriptionLine3: "ポートフォリオを閲覧して、お問い合わせや予約についてお気軽にお問い合わせください。",
        aboutThisWebsiteCopyrightTitle: "著作権 © 2024",
        aboutThisWebsiteBuildBaseText: "savoryの基本ソースコードを元に構築されました。",
    },
};

const i18n = createI18n({
    locale: 'vi', // set locale
    fallbackLocale: 'vi', // set fallback locale
    messages, // set locale messages
});

export default i18n;